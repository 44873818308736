var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v(" 返回")]
      ),
      _c("div", { staticClass: "pageTitle" }, [_vm._v("订单详情")]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "120px",
            model: _vm.formInline,
            rules: _vm.rules,
          },
        },
        [
          _c("div", { staticClass: "section-title" }, [_vm._v("订单信息")]),
          _c("el-form-item", { attrs: { label: "订单状态:" } }, [
            _vm._v(_vm._s(_vm.formInline.stateDesc)),
          ]),
          _c("el-form-item", { attrs: { label: "支付时间:" } }, [
            _vm._v(
              _vm._s(_vm._f("timeFilter")(_vm.formInline.payTime, _vm.that))
            ),
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  !_vm.$route.query.isEdit
                    ? _c("el-form-item", { attrs: { label: "车牌号:" } }, [
                        _vm._v(_vm._s(_vm.formInline.plateNumber)),
                      ])
                    : _vm._e(),
                  _vm.$route.query.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "车牌号:", prop: "plateNumber" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: 8, placeholder: "请输入内容" },
                            model: {
                              value: _vm.formInline.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "plateNumber", $$v)
                              },
                              expression: "formInline.plateNumber",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  !_vm.$route.query.isEdit
                    ? _c("el-form-item", { attrs: { label: "车牌颜色:" } }, [
                        _vm._v(_vm._s(_vm.formInline.plateColor)),
                      ])
                    : _vm._e(),
                  _vm.$route.query.isEdit
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.License_plate_color"),
                            prop: "plateColorCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择车牌颜色" },
                              model: {
                                value: _vm.formInline.plateColorCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "plateColorCode",
                                    $$v
                                  )
                                },
                                expression: "formInline.plateColorCode",
                              },
                            },
                            _vm._l(_vm.colorList, function (v) {
                              return _c("el-option", {
                                key: v.code,
                                attrs: { label: v.desc, value: v.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.formInline.phoneNumber
            ? _c("el-form-item", { attrs: { label: "手机号:" } }, [
                _vm._v(_vm._s(_vm.formInline.phoneNumber)),
              ])
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "购买渠道:" } }, [
                    _vm._v(_vm._s(_vm.formInline.buyTypeDesc)),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "实付金额:" } }, [
                    _vm._v(
                      _vm._s(_vm._f("priceFilter")(_vm.formInline.actualAmount))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "支付方式:" } }, [
                    _vm._v(_vm._s(_vm.formInline.payTypeDesc)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "section-title" }, [_vm._v("商品信息")]),
          _c("el-form-item", { attrs: { label: "商品名称:" } }, [
            _vm._v(_vm._s(_vm.formInline.parkCardTitle)),
          ]),
          _c("el-form-item", { attrs: { label: "商户名称:" } }, [
            _vm._v(_vm._s(_vm.formInline.operatorName)),
          ]),
          _c("el-form-item", { attrs: { label: "适用范围:" } }, [
            _vm._v(_vm._s(_vm.formInline.useRangeDesc)),
          ]),
          _c("el-form-item", { attrs: { label: "生效开始日期:" } }, [
            _vm._v(
              _vm._s(_vm._f("timeFilter")(_vm.formInline.startDate, _vm.that))
            ),
          ]),
          _vm.formInline.validityPeriod
            ? _c("el-form-item", { attrs: { label: "有效天数:" } }, [
                _vm._v(_vm._s(_vm.formInline.validityPeriod)),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "生效结束日期:" } }, [
            _vm._v(
              _vm._s(_vm._f("timeFilter")(_vm.formInline.endDate, _vm.that))
            ),
          ]),
        ],
        1
      ),
      _vm.$route.query.isEdit
        ? _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.history.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }