<!--
 * @Descripttion: 
 * @Author: luxiyuan
 * @Date: 2020-06-28 14:15:35
 * @LastEditors: luxiyuan
 * @LastEditTime: 2020-08-13 10:55:48
-->
<template>
  <div class="content">
    <el-button type="text" @click="$router.go(-1)"> 返回</el-button>
    <div class="pageTitle">订单详情</div>
    <el-form label-width="120px" ref="form" :model="formInline" :rules="rules">
      <div class="section-title">订单信息</div>
      <el-form-item label="订单状态:">{{ formInline.stateDesc }}</el-form-item>
      <el-form-item label="支付时间:">{{ formInline.payTime | timeFilter(that) }}</el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="车牌号:" v-if="!$route.query.isEdit">{{ formInline.plateNumber }}</el-form-item>
          <el-form-item label="车牌号:" v-if="$route.query.isEdit" prop="plateNumber">
            <el-input
              :maxlength="8"
              v-model="formInline.plateNumber"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车牌颜色:" v-if="!$route.query.isEdit">{{ formInline.plateColor }}</el-form-item>
          <el-form-item :label="$t('searchModule.License_plate_color')" v-if="$route.query.isEdit" prop="plateColorCode">
            <el-select
              v-model="formInline.plateColorCode"
              placeholder="请选择车牌颜色"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in colorList"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="手机号:" v-if="formInline.phoneNumber">{{ formInline.phoneNumber }}</el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="购买渠道:">{{ formInline.buyTypeDesc }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实付金额:">{{ formInline.actualAmount | priceFilter }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付方式:">{{ formInline.payTypeDesc }}</el-form-item>
        </el-col>
      </el-row>
      <div class="section-title">商品信息</div>
      <el-form-item label="商品名称:">{{ formInline.parkCardTitle }}</el-form-item>
      <el-form-item label="商户名称:">{{ formInline.operatorName }}</el-form-item>
      <el-form-item label="适用范围:">{{ formInline.useRangeDesc }}</el-form-item>
      <el-form-item label="生效开始日期:">{{ formInline.startDate | timeFilter(that) }}</el-form-item>
      <el-form-item label="有效天数:" v-if="formInline.validityPeriod">{{ formInline.validityPeriod }}</el-form-item>
      <el-form-item label="生效结束日期:">{{ formInline.endDate | timeFilter(that) }}</el-form-item>
    </el-form>
    <div style="text-align: center" v-if="$route.query.isEdit">
      <el-button type="primary" @click="saveEdit">{{ $t('button.preservation') }}</el-button>
      <el-button @click="$router.history.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'financialparkRuleDetail',
  filters: {
    priceFilter (value) {
      if (!value) return
      return (value / 100).toFixed(2) + '元'
    },
    timeFilter (value, that) {
      if (!value) return
      return that.$moment(parseInt(value)).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  data () {
    const plateNumberRules = (rule, value, callback) => {
      let rel = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{4,5}([A-Z0-9挂学警港澳使]{1}|应急)$/;
      if (rel.test(value)) {
        callback();
      } else {
        callback('车牌号格式不正确，例：冀AAAAAA');
      }
    }
    return {
      formInline: {
        plateNumber: '',
        plateColorCode: ''
      },
      that: this,
      colorList: [],
      rules: {
        plateNumber: [
          {
            required: true,
            message: '必填',
            trigger: 'blur'
          },
          {
            validator: plateNumberRules,
            trigger: 'blur'
          }
        ],
        plateColorCode: [
          {
            required: true,
            message: '必填',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.query.isEdit) {
      this.getColor()
    }
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$axios
        .get(`/acb/2.0/parkCard/detail`, {
          data: {
            parkCardId: this.$route.query.parkCardId,
          },
        })
        .then(res => {
          if (res.state == 0) {
            this.formInline = res.value;
            if (this.formInline.useRangeDesc === '全部车场') {
              this.getScopes(res.value.operationId)
            }
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 获取全部车场名称
    getScopes(operationId) {
      const opt = {
        url: '/acb/2.0/park/currentUserParkList',
        method: 'get',
        data: {
          operationId
        },
        success: res => {
          let arr = []
          res.value.forEach(item => {
            arr.push(item.parkName)
          })
          this.formInline.useRangeDesc = arr.join('、')
        }
      }
      this.$request(opt)
    },
    getColor() {
      this.$axios.get('/acb/2.0/specialplate/plateColor/list').then(res => {
        if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, '提示');
        }
      });
    },
    // 保存编辑
    saveEdit() {
      const data = {
        parkCardId: this.$route.query.parkCardId,
        plateNumber: this.formInline.plateNumber,
        plateColor: this.formInline.plateColorCode
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post('/acb/2.0/parkCard/update', {
            data,
          }).then(res => {
            if (res.state == 0) {
              this.$router.go(-1);
            } else {
              this.$alert(res.desc, '提示');
            }
          });
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.content
  padding 20px
.pageTitle
  line-height 30px
  font-size 16px
.section-title
  line-height 35px
  background-color #ccc
  color #656565
  margin 10px 0
  font-size 14px
  padding-left 20px
</style>
